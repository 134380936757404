import isEmpty from 'lodash/isEmpty'
import mixpanel from 'mixpanel-browser'

import getEnv from 'utils/getEnv'

/**
 * Standardised MixPanel browser integration.
 * This should be called before the root React application is mounted.
 *
 * The token should be defined:
 *  - `VITE_MIXPANEL_PROJECT_TOKEN`
 */
export const mixpanelInit = ({ debug = false }: { debug?: boolean }): void => {
  const { VITE_MIXPANEL_PROJECT_TOKEN } = getEnv()

  /**
   * In order to simplify our mixpanel usage and prevent
   * having to check for tokens everywhere via a wrapped hook,
   * we pass a dummy token if the token is not provided,
   * and warn in the console that the real token is missing.
   */
  const backupToken =
    'Please provide mixpanel with a VITE_MIXPANEL_PROJECT_TOKEN in your env'
  const token = !isEmpty(VITE_MIXPANEL_PROJECT_TOKEN)
    ? VITE_MIXPANEL_PROJECT_TOKEN
    : backupToken

  // notify through the console if initialisation was skipped, rather than being silent.

  if (!VITE_MIXPANEL_PROJECT_TOKEN) {
    console.warn(
      'Mixpanel initialisation failed. Token env not provided (RENDERER_VITE_MIXPANEL_PROJECT_TOKEN).',
    )
  }

  // @ts-ignore Ignore this error as we want it to fail if it's not defined
  mixpanel.init(token, {
    debug: debug && token !== backupToken,
    persistence: 'localStorage',
  })
}
