/**
 * Vixen server domain.
 *
 * Set via the environment variable `VITE_API_DOMAIN`.
 *
 * @category HTTP
 */
export const API_DOMAIN = `${
  // @ts-ignore
  import.meta.env.VITE_API_DOMAIN || window.location.origin
}`

/**
 * A list of HTTP verbs.
 *
 * @category HTTP
 */
export type HttpVerbs = 'PUT' | 'GET' | 'POST' | 'PATCH' | 'DELETE'

type RequestData<TRequestEntity> = {
  readonly payload: TRequestEntity
  readonly verb: HttpVerbs
  readonly accessToken?: string
}

/**
 * HTTP Payload
 *
 * @category HTTP
 * @internal
 */
export type Payload = {
  method: HttpVerbs
  headers: Headers
  body?: string | FormData
}

/**
 * Build a HTTP request payload.
 *
 * @category HTTP
 * @internal
 */
export const assemblePayload = <TRequestEntity>({
  payload,
  verb = 'GET',
  accessToken,
}: RequestData<TRequestEntity>): Payload => {
  const headers = new Headers()
  let body: Payload['body']

  const isMultipart = payload instanceof FormData

  // standard headers for http rest calls.
  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`)
  }
  // allow fetch to automatically assemble the boundary for multipart formdata.
  if (!isMultipart) {
    headers.append('Content-Type', 'application/json; charset=utf-8')
  }

  // transformations to request payload.
  if (isMultipart) {
    body = payload as unknown as FormData
  } else if (verb !== 'GET') {
    body = JSON.stringify(payload)
  }

  return {
    method: verb,
    headers,
    body,
  }
}
