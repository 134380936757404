import { memoize } from 'lodash'

/**
 * Config objects returned by {@link useSettings}.
 *
 * @category Settings
 */
export type ConfigOptions = {
  /** The default language  */
  defaultLanguage: string
  /** Other languages made available */
  languages: string[]

  /** UUID to force the vx-config (defaults to the first config in the bundle) */
  vxConfig?: string
  /** URL to the help centre */
  helpCentreUrl?: string
  /**
   * URL to help centre article for managing audio
   */
  manageAudioUrl?: string
}

type DefaultOptions = Partial<ConfigOptions>

const defaults: DefaultOptions = {}

class Settings {
  private initialized: boolean = false

  // @ts-ignore
  public options: ConfigOptions

  constructor(options: ConfigOptions) {
    if (!this.initialized) {
      this.initialized = true
      this.options = { ...defaults, ...options }
      return this
    }
    return this
  }

  public updateSettings(options: ConfigOptions): this {
    this.options = {
      ...this.options,
      ...options,
    }
    return this
  }
}

let SETTINGS: Settings

/**
 * Initialise the settings for {@link getSettings}.
 *
 * @category Settings
 */
export const setupSettings = (options: ConfigOptions): void => {
  if (SETTINGS) {
    console.warn('Settings has already been defined')
  }
  SETTINGS = new Settings(options)
}

/**
 * Return type for {@link GetSettings}.
 *
 * @category Settings
 */
type GetSettings = {
  /** Settings object. */
  settings: ConfigOptions

  /** Update the settings object */
  updateSettings: (options: ConfigOptions) => void

  /**
   * Get the translation for a given language.
   *
   * @param transMap translatable-map
   * @param lang language (defaults to the content language)
   * @template T type of the translatable-map
   */
  getTranslation: <T>(
    transMap: Record<string, T> | null | undefined,
  ) => T | undefined
}

/**
 * A hook to retrieve the settings from the settings file.
 *
 * Initialise the settings with {@link setupSettings}.
 *
 * @category Settings
 */
export const getSettings = memoize((): GetSettings => {
  return {
    settings: SETTINGS.options,
    updateSettings: (options: ConfigOptions) =>
      SETTINGS.updateSettings(options),
    getTranslation: <T>(
      transMap: Record<string, T> | null | undefined,
      lang: string = SETTINGS.options.defaultLanguage,
    ) => (transMap ? transMap[lang] : undefined),
  }
})
