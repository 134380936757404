import mixpanel from 'mixpanel-browser'

// System Events
export enum ANALYTICS_SYSTEM_EVENTS {
  SET_PASSWORD = 'set password',
  FORGOTTEN_PASSWORD_FORM_COMPLETED = 'forgotten password form completed',
  LOGIN_COMPLETED = 'login completed',
  LOGOUT_COMPLETED = 'logout completed',
  SESSION_START = '$session_start',
  SESSION_END = '$session_end',
  MP_WEB_PAGE_VIEW = '$mp_web_page_view',
  CHANGES_SAVED = 'changes saved',
  CHANGES_PUBLISHED = 'changes published',
  BACK_ARROW_CLICKED = 'back arrow clicked',
  EXTERNAL_LINK_CLICKED = 'external link clicked',
  TOOLTIP_CLICKED = 'tooltip clicked',
}

// Dashboard Events
export enum ANALYTICS_DASHBOARD_EVENTS {
  DASHBOARD_TILE_SELECTED = 'dashboard tile selected',
  DESKTOP_APP_DOWNLOAD_VERSION_SELECTED = 'desktop app download version selected',
}

// PWA Theme Events
export enum ANALYTICS_PWA_THEME_EVENTS {
  EVENT_DISPLAY_NAME_EDITED = 'event display name edited',
  APPEARANCE_MODE_SELECTED = 'appearance mode selected',
  EVENT_LOGO_UPLOADED = 'event logo uploaded',
  EVENT_LOGO_DELETED = 'event logo deleted',
  SPONSOR_STRIP_UPLOADED = 'sponsor strip uploaded',
  SPONSOR_STRIP_DELETED = 'sponsor strip deleted',
  BACKGROUND_IMAGE_UPLOADED = 'background image uploaded',
  BACKGROUND_IMAGE_DELETED = 'background image deleted',
}

// PWA Content Events
export enum ANALYTICS_PWA_CONTENT_EVENTS {
  ACTIVE_MESSAGE_EDITED = 'active message edited',
  INACTIVE_MESSAGE_EDITED = 'inactive message edited',
  INFO_MODAL_EDITED = 'info modal edited',
  TRANSCRIPT_EDITED = 'transcript edited',
  EVENT_DESCRIPTION_EDITED = 'event description edited',
}

// Manage Audio Events
export enum ANALYTICS_MANAGE_AUDIO_EVENTS {
  SYNC_METHOD_CHANGED = 'sync method changed',
  AUDIO_UPLOADED = 'audio uploaded',
  AUDIO_DELETED = 'audio deleted',
  SMPTE_TIMECODE_CHANGED = 'SMPTE timecode changed',
  SMPTE_TIMECODE_COPIED = 'SMPTE timecode copied',
  PORT_NUMBER_EDITED = 'port number edited',
  UDP_MESSAGE_COPIED = 'UDP message copied',
  UDP_MESSAGE_CHANGED = 'UDP message changed',
  PLAYLIST_NAME_EDITED = 'playlist name edited',
  PLAYLIST_DELETED = 'playlist deleted',
  PLAYLIST_ADDED = 'playlist added',
  PLAYLIST_DUPLICATED = 'playlist duplicated',
}

// PWA Links and QR Code Events
export enum ANALYTICS_PWA_LINKS_QR_EVENTS {
  QR_CODE_DOWNLOAD_BUTTON_CLICKED = 'QR code download button clicked',
  PWA_LINK_COPIED = 'pwa link copied',
}

/**
 *
 * Centralise the use of 3rd party analytics in the app.
 * @returns
 */
export const useAnalytics = () => {
  const pageview = (meta: Record<string, string>): void =>
    mixpanel.track_pageview(meta)

  const track = (eventName: string, properties?: Record<string, any>): void =>
    mixpanel.track(eventName, properties)

  const setUserProperties = (properties: Record<string, any>): void =>
    mixpanel.people.set(properties)

  const identify = (
    id: string | undefined,
    meta: Record<string, any>,
  ): void => {
    if (id) {
      mixpanel.identify(id)
      setUserProperties(meta)
    }
  }

  return { pageview, track, setUserProperties, identify }
}
