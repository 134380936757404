import { useAnalytics } from './useAnalytics'
import { useIdentifyAnalytics } from './useIdentifyAnalytics'

export const useTrack = (): {
  track: (eventName: string, properties?: Record<string, string>) => void
} => {
  const { meta } = useIdentifyAnalytics()
  const { track: rawTrack } = useAnalytics()

  const track = (
    eventName: string,
    properties?: Record<string, string>,
  ): void => rawTrack(eventName, { ...meta, ...properties })

  return { track }
}
