import type { CognitoUser } from '@aws-amplify/auth'
import type { DateTime } from 'luxon'

/**
 * Attributes in the Cognito user profile
 */
export type UserAttributes = {
  /** The user's email address (this is how they log in) */
  email: string
  /** True if the user's email address is verified */
  email_verified?: boolean
  /** The user's display name */
  name?: string
  /** The user's avatar */
  picture?: string
  /** The user's ID */
  sub?: string
}

/**
 * Attributes from the user's access token metadata that we use
 */
export type UserMetadata = {
  jwtToken: string
  payload: {
    /** The user's UUID */
    sub: string
    /** Login time */
    auth_time: DateTime
    /** User groups the user is part of */
    'cognito:groups': string[]
  }
}

/**
 * This is the user returned by Cognito's getCurrentUser call.
 */
export type AuthUser = CognitoUser & {
  attributes: UserAttributes
}

export type AuthUserChallenge = CognitoUser & {
  challengeParam: {
    requiredAttributes: []
    userAttributes: {
      email: string
      email_verified: boolean
      name: string
    }
  }
}

export enum AuthenticationStatus {
  LoggedIn = 'logged-in',
  LoggedOut = 'logged-out',
  Verifying = 'verifying',
}

export type AuthenticationState = {
  readonly status: AuthenticationStatus
  /** From the identity token */
  readonly userAttributes?: UserAttributes
  /** From the access token */
  readonly userMetadata?: UserMetadata
}

/**
 * User states as reported by Vixen.
 *
 * Maps directly to the UserState enum in Vixen.
 *
 * Only provided by Vixen user APIs, will not be provided by Cognito.
 */
export enum VixenUserState {
  INVITED = 'invited',
  RESET_REQUIRED = 'reset-required',
  ACTIVE = 'active',
  DISABLED = 'disabled',
  DELETED = 'deleted',
}

/**
 * This is not all groups, just groups known to Vixen, that have meaning in
 * the business logic applied by the Console.
 */
export enum VixenGroups {
  EDITORS = 'vixen:editors',
  PUBLISHERS = 'vixen:publishers',
  ADMINS = 'admins',
}
