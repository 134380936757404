/* eslint-disable */
import * as z from 'zod'
import { camel, snake } from 'radash'

import {
  Struct,
  Entity,
  Meta,
  recase,
  CasingFunction,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { NestedEntity, NestedEntitySchema } from '@apsys/gazelle'

import * as VixenCore from 'schemas/vixen-core'
import * as VixenAssets from 'schemas/vixen-assets'
import * as OcelotContent from 'schemas/ocelot-content'

export const meta: Meta = {
  name: 'Event Sync Festival Config API',
  moduleName: 'esfestival-config-api',
  version: '1.5.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- es-integration ---
export interface EsIntegrationSchema {
  name?: string
  key?: string
}

export interface IEsIntegration {
  name: string
  key: string
}

// === structs ===
// === entities ===
export interface ManualIntegrationSchema extends EsIntegrationSchema {
  timeline?: Array<ManualIntegration.ManualTimelineEntrySchema>
}

export interface IManualIntegration extends IEsIntegration {
  timeline: Array<ManualIntegration.ManualTimelineEntry>
}

type ManualIntegrationSchemaType = z.Schema<
  ManualIntegration,
  z.ZodTypeDef,
  ManualIntegrationSchema
>

export const ManualIntegrationSchema = z
  .lazy(() =>
    z.object({
      name: z.string().default(''),
      key: z.string().default(''),
      timeline: z
        .array(ManualIntegration.ManualTimelineEntrySchema)
        .default([]),
    }),
  )
  .transform(
    value => new ManualIntegration(recase(value, snake)),
  ) satisfies ManualIntegrationSchemaType

export class ManualIntegration
  extends Entity<ManualIntegrationSchema>
  implements IManualIntegration
{
  static readonly typename = 'manual-integration'
  static readonly schema: ManualIntegrationSchemaType = ManualIntegrationSchema
  static readonly parse = ManualIntegrationSchema.parse
  static readonly fields = ['name', 'key', 'timeline']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'manual-integration'

  name!: string
  key!: string
  timeline!: Array<ManualIntegration.ManualTimelineEntry>

  constructor(attrs: IManualIntegration) {
    super(attrs)
  }
}

export namespace ManualIntegration {
  export interface ManualTimelineEntrySchema {
    content_id: string
    audio: GazelleRefSchema | NestedEntitySchema
  }

  export interface IManualTimelineEntry {
    content_id: string
    audio: GazelleRef<VixenAssets.Audio> | NestedEntity<VixenAssets.Audio>
  }

  type ManualTimelineEntrySchemaType = z.Schema<
    ManualTimelineEntry,
    z.ZodTypeDef,
    ManualTimelineEntrySchema
  >

  export const ManualTimelineEntrySchema = z
    .lazy(() =>
      z.object({
        content_id: z.string(),
        audio: z.union([GazelleRefSchema, NestedEntitySchema]),
      }),
    )
    .transform(
      value => new ManualTimelineEntry(recase(value, snake)),
    ) satisfies ManualTimelineEntrySchemaType

  export class ManualTimelineEntry
    extends Struct<ManualTimelineEntrySchema>
    implements IManualTimelineEntry
  {
    static readonly typename = 'manual-timeline-entry'
    static readonly schema: ManualTimelineEntrySchemaType =
      ManualTimelineEntrySchema
    static readonly parse = ManualTimelineEntrySchema.parse
    static readonly fields = ['content_id', 'audio']
    static readonly casingFunction: CasingFunction = snake

    readonly typename = 'manual-timeline-entry'

    content_id!: string
    audio!: GazelleRef<VixenAssets.Audio> | NestedEntity<VixenAssets.Audio>

    constructor(attrs: IManualTimelineEntry) {
      super(attrs)
    }
  }
}

export interface UdpIntegrationSchema extends EsIntegrationSchema {
  port: number
  timeline?: Array<UdpIntegration.UdpTimelineEntrySchema>
}

export interface IUdpIntegration extends IEsIntegration {
  port: number
  timeline: Array<UdpIntegration.UdpTimelineEntry>
}

type UdpIntegrationSchemaType = z.Schema<
  UdpIntegration,
  z.ZodTypeDef,
  UdpIntegrationSchema
>

export const UdpIntegrationSchema = z
  .lazy(() =>
    z.object({
      name: z.string().default(''),
      key: z.string().default(''),
      port: z.number().int(),
      timeline: z.array(UdpIntegration.UdpTimelineEntrySchema).default([]),
    }),
  )
  .transform(
    value => new UdpIntegration(recase(value, snake)),
  ) satisfies UdpIntegrationSchemaType

export class UdpIntegration
  extends Entity<UdpIntegrationSchema>
  implements IUdpIntegration
{
  static readonly typename = 'udp-integration'
  static readonly schema: UdpIntegrationSchemaType = UdpIntegrationSchema
  static readonly parse = UdpIntegrationSchema.parse
  static readonly fields = ['name', 'key', 'port', 'timeline']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'udp-integration'

  name!: string
  key!: string
  port!: number
  timeline!: Array<UdpIntegration.UdpTimelineEntry>

  constructor(attrs: IUdpIntegration) {
    super(attrs)
  }
}

export namespace UdpIntegration {
  export interface UdpTimelineEntrySchema {
    content_id: string
    audio: GazelleRefSchema | NestedEntitySchema
  }

  export interface IUdpTimelineEntry {
    content_id: string
    audio: GazelleRef<VixenAssets.Audio> | NestedEntity<VixenAssets.Audio>
  }

  type UdpTimelineEntrySchemaType = z.Schema<
    UdpTimelineEntry,
    z.ZodTypeDef,
    UdpTimelineEntrySchema
  >

  export const UdpTimelineEntrySchema = z
    .lazy(() =>
      z.object({
        content_id: z.string(),
        audio: z.union([GazelleRefSchema, NestedEntitySchema]),
      }),
    )
    .transform(
      value => new UdpTimelineEntry(recase(value, snake)),
    ) satisfies UdpTimelineEntrySchemaType

  export class UdpTimelineEntry
    extends Struct<UdpTimelineEntrySchema>
    implements IUdpTimelineEntry
  {
    static readonly typename = 'udp-timeline-entry'
    static readonly schema: UdpTimelineEntrySchemaType = UdpTimelineEntrySchema
    static readonly parse = UdpTimelineEntrySchema.parse
    static readonly fields = ['content_id', 'audio']
    static readonly casingFunction: CasingFunction = snake

    readonly typename = 'udp-timeline-entry'

    content_id!: string
    audio!: GazelleRef<VixenAssets.Audio> | NestedEntity<VixenAssets.Audio>

    constructor(attrs: IUdpTimelineEntry) {
      super(attrs)
    }
  }
}

export interface TimecodeIntegrationSchema extends EsIntegrationSchema {
  timeline?: Array<TimecodeIntegration.TimecodeTimelineEntrySchema>
}

export interface ITimecodeIntegration extends IEsIntegration {
  timeline: Array<TimecodeIntegration.TimecodeTimelineEntry>
}

type TimecodeIntegrationSchemaType = z.Schema<
  TimecodeIntegration,
  z.ZodTypeDef,
  TimecodeIntegrationSchema
>

export const TimecodeIntegrationSchema = z
  .lazy(() =>
    z.object({
      name: z.string().default(''),
      key: z.string().default(''),
      timeline: z
        .array(TimecodeIntegration.TimecodeTimelineEntrySchema)
        .default([]),
    }),
  )
  .transform(
    value => new TimecodeIntegration(recase(value, snake)),
  ) satisfies TimecodeIntegrationSchemaType

export class TimecodeIntegration
  extends Entity<TimecodeIntegrationSchema>
  implements ITimecodeIntegration
{
  static readonly typename = 'timecode-integration'
  static readonly schema: TimecodeIntegrationSchemaType =
    TimecodeIntegrationSchema
  static readonly parse = TimecodeIntegrationSchema.parse
  static readonly fields = ['name', 'key', 'timeline']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'timecode-integration'

  name!: string
  key!: string
  timeline!: Array<TimecodeIntegration.TimecodeTimelineEntry>

  constructor(attrs: ITimecodeIntegration) {
    super(attrs)
  }
}

export namespace TimecodeIntegration {
  export interface TimecodeTimelineEntrySchema {
    content_id: string
    trigger: string
    start_point: number
    time_code: string
    audio: GazelleRefSchema | NestedEntitySchema
  }

  export interface ITimecodeTimelineEntry {
    content_id: string
    trigger: string
    start_point: number
    time_code: string
    audio: GazelleRef<VixenAssets.Audio> | NestedEntity<VixenAssets.Audio>
  }

  type TimecodeTimelineEntrySchemaType = z.Schema<
    TimecodeTimelineEntry,
    z.ZodTypeDef,
    TimecodeTimelineEntrySchema
  >

  export const TimecodeTimelineEntrySchema = z
    .lazy(() =>
      z.object({
        content_id: z.string(),
        trigger: z.string(),
        start_point: z.number(),
        time_code: z.string(),
        audio: z.union([GazelleRefSchema, NestedEntitySchema]),
      }),
    )
    .transform(
      value => new TimecodeTimelineEntry(recase(value, snake)),
    ) satisfies TimecodeTimelineEntrySchemaType

  export class TimecodeTimelineEntry
    extends Struct<TimecodeTimelineEntrySchema>
    implements ITimecodeTimelineEntry
  {
    static readonly typename = 'timecode-timeline-entry'
    static readonly schema: TimecodeTimelineEntrySchemaType =
      TimecodeTimelineEntrySchema
    static readonly parse = TimecodeTimelineEntrySchema.parse
    static readonly fields = [
      'content_id',
      'trigger',
      'start_point',
      'time_code',
      'audio',
    ]
    static readonly casingFunction: CasingFunction = snake

    readonly typename = 'timecode-timeline-entry'

    content_id!: string
    trigger!: string
    start_point!: number
    time_code!: string
    audio!: GazelleRef<VixenAssets.Audio> | NestedEntity<VixenAssets.Audio>

    constructor(attrs: ITimecodeTimelineEntry) {
      super(attrs)
    }
  }
}

export interface EventInfoSchema {
  interactive_id: string
  customer_slug: string
  event_slug: string
  audience: string
}

export interface IEventInfo {
  interactive_id: string
  customer_slug: string
  event_slug: string
  audience: string
}

type EventInfoSchemaType = z.Schema<EventInfo, z.ZodTypeDef, EventInfoSchema>

export const EventInfoSchema = z
  .lazy(() =>
    z.object({
      interactive_id: z.string(),
      customer_slug: z.string(),
      event_slug: z.string(),
      audience: z.string(),
    }),
  )
  .transform(
    value => new EventInfo(recase(value, snake)),
  ) satisfies EventInfoSchemaType

export class EventInfo extends Entity<EventInfoSchema> implements IEventInfo {
  static readonly typename = 'event-info'
  static readonly schema: EventInfoSchemaType = EventInfoSchema
  static readonly parse = EventInfoSchema.parse
  static readonly fields = [
    'interactive_id',
    'customer_slug',
    'event_slug',
    'audience',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'event-info'

  interactive_id!: string
  customer_slug!: string
  event_slug!: string
  audience!: string

  constructor(attrs: IEventInfo) {
    super(attrs)
  }
}

export interface ConnectionInfoSchema {
  bridge_endpoint: string
  socket_endpoint: string
}

export interface IConnectionInfo {
  bridge_endpoint: string
  socket_endpoint: string
}

type ConnectionInfoSchemaType = z.Schema<
  ConnectionInfo,
  z.ZodTypeDef,
  ConnectionInfoSchema
>

export const ConnectionInfoSchema = z
  .lazy(() =>
    z.object({
      bridge_endpoint: z.string(),
      socket_endpoint: z.string(),
    }),
  )
  .transform(
    value => new ConnectionInfo(recase(value, snake)),
  ) satisfies ConnectionInfoSchemaType

export class ConnectionInfo
  extends Entity<ConnectionInfoSchema>
  implements IConnectionInfo
{
  static readonly typename = 'connection-info'
  static readonly schema: ConnectionInfoSchemaType = ConnectionInfoSchema
  static readonly parse = ConnectionInfoSchema.parse
  static readonly fields = ['bridge_endpoint', 'socket_endpoint']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'connection-info'

  bridge_endpoint!: string
  socket_endpoint!: string

  constructor(attrs: IConnectionInfo) {
    super(attrs)
  }
}

export interface EsConfigSchema
  extends VixenCore.NamedSchema,
    OcelotContent.SlottedContentSchema {
  integration: GazelleRefSchema | NestedEntitySchema
  playlists?: Array<GazelleRefSchema | NestedEntitySchema>
  class_name?: string | null
  background?: GazelleRefSchema | NestedEntitySchema | null
  logo?: GazelleRefSchema | NestedEntitySchema | null
  sponsor?: GazelleRefSchema | NestedEntitySchema | null
}

export interface IEsConfig
  extends VixenCore.INamed,
    OcelotContent.ISlottedContent {
  integration: GazelleRef<IEsIntegration> | NestedEntity<IEsIntegration>
  playlists: Array<GazelleRef<IEsIntegration> | NestedEntity<IEsIntegration>>
  class_name?: string
  background?:
    | GazelleRef<VixenAssets.Picture>
    | NestedEntity<VixenAssets.Picture>
  logo?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>
  sponsor?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>
}

type EsConfigSchemaType = z.Schema<EsConfig, z.ZodTypeDef, EsConfigSchema>

export const EsConfigSchema = z
  .lazy(() =>
    z.object({
      content: z
        .array(OcelotContent.SlottedContent.ContentSlotSchema)
        .default([]),
      name: z.record(z.string()),
      integration: z.union([GazelleRefSchema, NestedEntitySchema]),
      playlists: z
        .array(z.union([GazelleRefSchema, NestedEntitySchema]))
        .default([]),
      class_name: z.string().nullish(),
      background: z.union([GazelleRefSchema, NestedEntitySchema]).nullish(),
      logo: z.union([GazelleRefSchema, NestedEntitySchema]).nullish(),
      sponsor: z.union([GazelleRefSchema, NestedEntitySchema]).nullish(),
    }),
  )
  .transform(
    value => new EsConfig(recase(value, snake)),
  ) satisfies EsConfigSchemaType

export class EsConfig extends Entity<EsConfigSchema> implements IEsConfig {
  static readonly typename = 'es-config'
  static readonly schema: EsConfigSchemaType = EsConfigSchema
  static readonly parse = EsConfigSchema.parse
  static readonly fields = [
    'content',
    'name',
    'integration',
    'playlists',
    'class_name',
    'background',
    'logo',
    'sponsor',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'es-config'

  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  integration!: GazelleRef<IEsIntegration> | NestedEntity<IEsIntegration>
  playlists!: Array<GazelleRef<IEsIntegration> | NestedEntity<IEsIntegration>>
  class_name?: string
  background?:
    | GazelleRef<VixenAssets.Picture>
    | NestedEntity<VixenAssets.Picture>
  logo?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>
  sponsor?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>

  constructor(attrs: IEsConfig) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenAssets.INTERFACES &
  OcelotContent.INTERFACES & {
    'es-integration': IEsIntegration
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenAssets.ENTITIES,
  ...OcelotContent.ENTITIES,
  'manual-integration': ManualIntegration,
  'udp-integration': UdpIntegration,
  'timecode-integration': TimecodeIntegration,
  'event-info': EventInfo,
  'connection-info': ConnectionInfo,
  'es-config': EsConfig,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}
