import { VixenGroups } from '../types'

/** A list of roles in order of display priority */
export const rolesInOrder = [
  VixenGroups.ADMINS,
  VixenGroups.PUBLISHERS,
  VixenGroups.EDITORS,
]

export enum IdentifyQueryKeys {
  AuthUser = 'identify:authUserQuery',
  CurrentSession = 'identify:currentSessionQuery',
  Token = 'identify:currentToken',
}
