import { IdentifyAnalyticsMeta } from './constants'
import { useAnalytics } from './useAnalytics'

import { getIsAuthenticated, useAuthState } from 'hooks/useAuthState'
import { useEsConfig } from 'hooks/useEsConfig'
import { getVersion } from 'utils/getVersion'
import { getSettings } from 'utils/settings'

type IdentifyProps =
  | {
      id?: string
      meta?: Partial<ReturnProps['meta']>
    }
  | undefined

type ReturnProps = {
  meta: {
    [IdentifyAnalyticsMeta.VERSION]: string
    [IdentifyAnalyticsMeta.LANGUAGE]: string
    [IdentifyAnalyticsMeta.ENVIRONMENT]: string
    [IdentifyAnalyticsMeta.CONSOLE_URL]: string
    [IdentifyAnalyticsMeta.CUSTOMER]?: string
    [IdentifyAnalyticsMeta.CUSTOMER_LICENSE]?: string
    [IdentifyAnalyticsMeta.USER_ID]?: string
    [IdentifyAnalyticsMeta.EVENT_NAME]?: string
    // Reserved mixpanel property names
    $email?: string
    $name?: string
  }
  identify: (props: IdentifyProps) => void
}

/**
 * Generate some common meta for use in analytics tracking.
 *
 * Based off of the info on the common dimensions on the page:
 * https://artprocessors.atlassian.net/wiki/spaces/APS/pages/2131132417/Event+Sync+Console+-+Tracking+plan+-+Product+health
 */
export const useIdentifyAnalytics = (): ReturnProps => {
  const { settings } = getSettings()
  const authState = useAuthState()
  const { userMetadata, userAttributes } = authState
  const { getTranslation } = getSettings()
  const { data } = useEsConfig()
  const { identify: _identify } = useAnalytics()

  const version = getVersion()
  const consoleVersion = version ? version : 'Development'

  const customer = data?.customer?.display_name
  const eventName = getTranslation(data?.document.name)

  const loggedInMeta =
    getIsAuthenticated(authState) &&
    userMetadata &&
    userAttributes &&
    eventName
      ? {
          [IdentifyAnalyticsMeta.CUSTOMER]: customer,
          [IdentifyAnalyticsMeta.USER_ID]: userAttributes.email,
          [IdentifyAnalyticsMeta.EVENT_NAME]: eventName,

          // TODO: Not available at this time
          [IdentifyAnalyticsMeta.CUSTOMER_LICENSE]: 'LICENSE NOT CONFIGURED',
          $email: userAttributes.email ?? undefined,
          $name: userAttributes.name ?? undefined,
        }
      : {}

  const meta = {
    [IdentifyAnalyticsMeta.VERSION]: consoleVersion,
    [IdentifyAnalyticsMeta.LANGUAGE]: settings.defaultLanguage,
    [IdentifyAnalyticsMeta.ENVIRONMENT]: import.meta.env
      .VITE_PLADIA_DEPLOY_ENV,
    [IdentifyAnalyticsMeta.CONSOLE_URL]: window.location.origin,
    ...loggedInMeta,
  }

  const identify = (props: IdentifyProps): void => {
    _identify(props?.id, {
      ...meta,
      ...props?.meta,
    })
  }

  return {
    meta,
    identify,
  }
}
