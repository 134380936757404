/* eslint-disable */
import * as z from 'zod'
import { camel, snake } from 'radash'

import {
  Struct,
  Entity,
  Meta,
  recase,
  CasingFunction,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { NestedEntity, NestedEntitySchema } from '@apsys/gazelle'

import * as VixenCore from 'schemas/vixen-core'
import * as VixenAssets from 'schemas/vixen-assets'

export const meta: Meta = {
  name: 'Ocelot Content',
  moduleName: 'ocelot-content',
  version: '0.16.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- content-block ---
export interface ContentBlockSchema
  extends VixenCore.StyledNamedSchema,
    VixenCore.RoutableSchema {
  class_name?: string | null
}

export interface IContentBlock
  extends VixenCore.IStyledNamed,
    VixenCore.IRoutable {
  class_name?: string
}

// --- slotted-content ---
export interface SlottedContentSchema {
  content?: Array<SlottedContent.ContentSlotSchema>
}

export interface ISlottedContent {
  content: Array<SlottedContent.ContentSlot>
}

export namespace SlottedContent {
  export interface ContentSlotSchema {
    slot: string
    content: GazelleRefSchema | NestedEntitySchema
  }

  export interface IContentSlot {
    slot: string
    content: GazelleRef<VixenCore.IContent> | NestedEntity<VixenCore.IContent>
  }

  type ContentSlotSchemaType = z.Schema<
    ContentSlot,
    z.ZodTypeDef,
    ContentSlotSchema
  >

  export const ContentSlotSchema = z
    .lazy(() =>
      z.object({
        slot: z.string(),
        content: z.union([GazelleRefSchema, NestedEntitySchema]),
      }),
    )
    .transform(
      value => new ContentSlot(recase(value, snake)),
    ) satisfies ContentSlotSchemaType

  export class ContentSlot
    extends Struct<ContentSlotSchema>
    implements IContentSlot
  {
    static readonly typename = 'content-slot'
    static readonly schema: ContentSlotSchemaType = ContentSlotSchema
    static readonly parse = ContentSlotSchema.parse
    static readonly fields = ['slot', 'content']
    static readonly casingFunction: CasingFunction = snake

    readonly typename = 'content-slot'

    slot!: string
    content!: GazelleRef<VixenCore.IContent> | NestedEntity<VixenCore.IContent>

    constructor(attrs: IContentSlot) {
      super(attrs)
    }
  }
}

// === structs ===
// === entities ===
export interface InternalContentPageSchema
  extends VixenCore.NamedSchema,
    VixenCore.RoutableSchema {
  content: GazelleRefSchema | NestedEntitySchema
}

export interface IInternalContentPage
  extends VixenCore.INamed,
    VixenCore.IRoutable {
  content: GazelleRef<VixenCore.IContent> | NestedEntity<VixenCore.IContent>
}

type InternalContentPageSchemaType = z.Schema<
  InternalContentPage,
  z.ZodTypeDef,
  InternalContentPageSchema
>

export const InternalContentPageSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      content: z.union([GazelleRefSchema, NestedEntitySchema]),
    }),
  )
  .transform(
    value => new InternalContentPage(recase(value, snake)),
  ) satisfies InternalContentPageSchemaType

export class InternalContentPage
  extends Entity<InternalContentPageSchema>
  implements IInternalContentPage
{
  static readonly typename = 'internal-content-page'
  static readonly schema: InternalContentPageSchemaType =
    InternalContentPageSchema
  static readonly parse = InternalContentPageSchema.parse
  static readonly fields = ['name', 'content']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'internal-content-page'

  name!: Record<string, string>
  content!: GazelleRef<VixenCore.IContent> | NestedEntity<VixenCore.IContent>

  constructor(attrs: IInternalContentPage) {
    super(attrs)
  }
}

export interface ExternalContentPageSchema extends VixenCore.RoutableSchema {
  url: string
}

export interface IExternalContentPage extends VixenCore.IRoutable {
  url: string
}

type ExternalContentPageSchemaType = z.Schema<
  ExternalContentPage,
  z.ZodTypeDef,
  ExternalContentPageSchema
>

export const ExternalContentPageSchema = z
  .lazy(() =>
    z.object({
      url: z.string(),
    }),
  )
  .transform(
    value => new ExternalContentPage(recase(value, snake)),
  ) satisfies ExternalContentPageSchemaType

export class ExternalContentPage
  extends Entity<ExternalContentPageSchema>
  implements IExternalContentPage
{
  static readonly typename = 'external-content-page'
  static readonly schema: ExternalContentPageSchemaType =
    ExternalContentPageSchema
  static readonly parse = ExternalContentPageSchema.parse
  static readonly fields = ['url']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'external-content-page'

  url!: string

  constructor(attrs: IExternalContentPage) {
    super(attrs)
  }
}

export interface ContentStackSchema extends VixenCore.ContentSchema {
  blocks: Array<GazelleRefSchema | NestedEntitySchema>
}

export interface IContentStack extends VixenCore.IContent {
  blocks: Array<GazelleRef<IContentBlock> | NestedEntity<IContentBlock>>
}

type ContentStackSchemaType = z.Schema<
  ContentStack,
  z.ZodTypeDef,
  ContentStackSchema
>

export const ContentStackSchema = z
  .lazy(() =>
    z.object({
      blocks: z.array(z.union([GazelleRefSchema, NestedEntitySchema])),
    }),
  )
  .transform(
    value => new ContentStack(recase(value, snake)),
  ) satisfies ContentStackSchemaType

export class ContentStack
  extends Entity<ContentStackSchema>
  implements IContentStack
{
  static readonly typename = 'content-stack'
  static readonly schema: ContentStackSchemaType = ContentStackSchema
  static readonly parse = ContentStackSchema.parse
  static readonly fields = ['blocks']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'content-stack'

  blocks!: Array<GazelleRef<IContentBlock> | NestedEntity<IContentBlock>>

  constructor(attrs: IContentStack) {
    super(attrs)
  }
}

export interface HtmlBlockSchema
  extends VixenCore.ContentSchema,
    ContentBlockSchema {
  html: Record<string, string>
}

export interface IHtmlBlock extends VixenCore.IContent, IContentBlock {
  html: Record<string, string>
}

type HtmlBlockSchemaType = z.Schema<HtmlBlock, z.ZodTypeDef, HtmlBlockSchema>

export const HtmlBlockSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      styled_name: z.record(z.string()),
      class_name: z.string().nullish(),
      html: z.record(z.string()),
    }),
  )
  .transform(
    value => new HtmlBlock(recase(value, snake)),
  ) satisfies HtmlBlockSchemaType

export class HtmlBlock extends Entity<HtmlBlockSchema> implements IHtmlBlock {
  static readonly typename = 'html-block'
  static readonly schema: HtmlBlockSchemaType = HtmlBlockSchema
  static readonly parse = HtmlBlockSchema.parse
  static readonly fields = ['name', 'styled_name', 'class_name', 'html']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'html-block'

  name!: Record<string, string>
  styled_name!: Record<string, string>
  class_name?: string
  html!: Record<string, string>

  constructor(attrs: IHtmlBlock) {
    super(attrs)
  }
}

export interface ExternalUrlCtaBlockSchema
  extends VixenCore.ContentSchema,
    ContentBlockSchema {
  body?: Record<string, string>
  text?: Record<string, string>
  url: string
  picture?: GazelleRefSchema | NestedEntitySchema | null
  target?: 'modal' | 'external'
}

export interface IExternalUrlCtaBlock
  extends VixenCore.IContent,
    IContentBlock {
  body: Record<string, string>
  text: Record<string, string>
  url: string
  picture?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>
  target: 'modal' | 'external'
}

type ExternalUrlCtaBlockSchemaType = z.Schema<
  ExternalUrlCtaBlock,
  z.ZodTypeDef,
  ExternalUrlCtaBlockSchema
>

export const ExternalUrlCtaBlockSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      styled_name: z.record(z.string()),
      class_name: z.string().nullish(),
      body: z.record(z.string()).default({}),
      text: z.record(z.string()).default({}),
      url: z.string(),
      picture: z.union([GazelleRefSchema, NestedEntitySchema]).nullish(),
      target: z.enum(['modal', 'external']).default('external'),
    }),
  )
  .transform(
    value => new ExternalUrlCtaBlock(recase(value, snake)),
  ) satisfies ExternalUrlCtaBlockSchemaType

export class ExternalUrlCtaBlock
  extends Entity<ExternalUrlCtaBlockSchema>
  implements IExternalUrlCtaBlock
{
  static readonly typename = 'external-url-cta-block'
  static readonly schema: ExternalUrlCtaBlockSchemaType =
    ExternalUrlCtaBlockSchema
  static readonly parse = ExternalUrlCtaBlockSchema.parse
  static readonly fields = [
    'name',
    'styled_name',
    'class_name',
    'body',
    'text',
    'url',
    'picture',
    'target',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'external-url-cta-block'

  name!: Record<string, string>
  styled_name!: Record<string, string>
  class_name?: string
  body!: Record<string, string>
  text!: Record<string, string>
  url!: string
  picture?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>
  target!: 'modal' | 'external'

  constructor(attrs: IExternalUrlCtaBlock) {
    super(attrs)
  }
}

export interface CarouselBlockSchema
  extends VixenCore.ContentSchema,
    ContentBlockSchema {
  pictures?: Array<CarouselBlock.PictureSlotSchema>
}

export interface ICarouselBlock extends VixenCore.IContent, IContentBlock {
  pictures: Array<CarouselBlock.PictureSlot>
}

type CarouselBlockSchemaType = z.Schema<
  CarouselBlock,
  z.ZodTypeDef,
  CarouselBlockSchema
>

export const CarouselBlockSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      styled_name: z.record(z.string()),
      class_name: z.string().nullish(),
      pictures: z.array(CarouselBlock.PictureSlotSchema).default([]),
    }),
  )
  .transform(
    value => new CarouselBlock(recase(value, snake)),
  ) satisfies CarouselBlockSchemaType

export class CarouselBlock
  extends Entity<CarouselBlockSchema>
  implements ICarouselBlock
{
  static readonly typename = 'carousel-block'
  static readonly schema: CarouselBlockSchemaType = CarouselBlockSchema
  static readonly parse = CarouselBlockSchema.parse
  static readonly fields = ['name', 'styled_name', 'class_name', 'pictures']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'carousel-block'

  name!: Record<string, string>
  styled_name!: Record<string, string>
  class_name?: string
  pictures!: Array<CarouselBlock.PictureSlot>

  constructor(attrs: ICarouselBlock) {
    super(attrs)
  }
}

export namespace CarouselBlock {
  export interface PictureSlotSchema {
    picture: GazelleRefSchema | NestedEntitySchema
    caption?: Record<string, string>
  }

  export interface IPictureSlot {
    picture:
      | GazelleRef<VixenAssets.Picture>
      | NestedEntity<VixenAssets.Picture>
    caption: Record<string, string>
  }

  type PictureSlotSchemaType = z.Schema<
    PictureSlot,
    z.ZodTypeDef,
    PictureSlotSchema
  >

  export const PictureSlotSchema = z
    .lazy(() =>
      z.object({
        picture: z.union([GazelleRefSchema, NestedEntitySchema]),
        caption: z.record(z.string()).default({}),
      }),
    )
    .transform(
      value => new PictureSlot(recase(value, snake)),
    ) satisfies PictureSlotSchemaType

  export class PictureSlot
    extends Struct<PictureSlotSchema>
    implements IPictureSlot
  {
    static readonly typename = 'picture-slot'
    static readonly schema: PictureSlotSchemaType = PictureSlotSchema
    static readonly parse = PictureSlotSchema.parse
    static readonly fields = ['picture', 'caption']
    static readonly casingFunction: CasingFunction = snake

    readonly typename = 'picture-slot'

    picture!:
      | GazelleRef<VixenAssets.Picture>
      | NestedEntity<VixenAssets.Picture>
    caption!: Record<string, string>

    constructor(attrs: IPictureSlot) {
      super(attrs)
    }
  }
}

export interface VimeoBlockSchema extends ContentBlockSchema {
  caption?: Record<string, string>
  url: string
}

export interface IVimeoBlock extends IContentBlock {
  caption: Record<string, string>
  url: string
}

type VimeoBlockSchemaType = z.Schema<
  VimeoBlock,
  z.ZodTypeDef,
  VimeoBlockSchema
>

export const VimeoBlockSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      styled_name: z.record(z.string()),
      class_name: z.string().nullish(),
      caption: z.record(z.string()).default({}),
      url: z.string(),
    }),
  )
  .transform(
    value => new VimeoBlock(recase(value, snake)),
  ) satisfies VimeoBlockSchemaType

export class VimeoBlock
  extends Entity<VimeoBlockSchema>
  implements IVimeoBlock
{
  static readonly typename = 'vimeo-block'
  static readonly schema: VimeoBlockSchemaType = VimeoBlockSchema
  static readonly parse = VimeoBlockSchema.parse
  static readonly fields = [
    'name',
    'styled_name',
    'class_name',
    'caption',
    'url',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'vimeo-block'

  name!: Record<string, string>
  styled_name!: Record<string, string>
  class_name?: string
  caption!: Record<string, string>
  url!: string

  constructor(attrs: IVimeoBlock) {
    super(attrs)
  }
}

export interface LogosBlockSchema
  extends VixenCore.ContentSchema,
    ContentBlockSchema {
  logos?: Array<LogosBlock.LogoSlotSchema>
}

export interface ILogosBlock extends VixenCore.IContent, IContentBlock {
  logos: Array<LogosBlock.LogoSlot>
}

type LogosBlockSchemaType = z.Schema<
  LogosBlock,
  z.ZodTypeDef,
  LogosBlockSchema
>

export const LogosBlockSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      styled_name: z.record(z.string()),
      class_name: z.string().nullish(),
      logos: z.array(LogosBlock.LogoSlotSchema).default([]),
    }),
  )
  .transform(
    value => new LogosBlock(recase(value, snake)),
  ) satisfies LogosBlockSchemaType

export class LogosBlock
  extends Entity<LogosBlockSchema>
  implements ILogosBlock
{
  static readonly typename = 'logos-block'
  static readonly schema: LogosBlockSchemaType = LogosBlockSchema
  static readonly parse = LogosBlockSchema.parse
  static readonly fields = ['name', 'styled_name', 'class_name', 'logos']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'logos-block'

  name!: Record<string, string>
  styled_name!: Record<string, string>
  class_name?: string
  logos!: Array<LogosBlock.LogoSlot>

  constructor(attrs: ILogosBlock) {
    super(attrs)
  }
}

export namespace LogosBlock {
  export interface LogoSlotSchema {
    light?: GazelleRefSchema | NestedEntitySchema | null
    dark?: GazelleRefSchema | NestedEntitySchema | null
  }

  export interface ILogoSlot {
    light?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>
    dark?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>
  }

  type LogoSlotSchemaType = z.Schema<LogoSlot, z.ZodTypeDef, LogoSlotSchema>

  export const LogoSlotSchema = z
    .lazy(() =>
      z.object({
        light: z.union([GazelleRefSchema, NestedEntitySchema]).nullish(),
        dark: z.union([GazelleRefSchema, NestedEntitySchema]).nullish(),
      }),
    )
    .transform(
      value => new LogoSlot(recase(value, snake)),
    ) satisfies LogoSlotSchemaType

  export class LogoSlot extends Struct<LogoSlotSchema> implements ILogoSlot {
    static readonly typename = 'logo-slot'
    static readonly schema: LogoSlotSchemaType = LogoSlotSchema
    static readonly parse = LogoSlotSchema.parse
    static readonly fields = ['light', 'dark']
    static readonly casingFunction: CasingFunction = snake

    readonly typename = 'logo-slot'

    light?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>
    dark?: GazelleRef<VixenAssets.Picture> | NestedEntity<VixenAssets.Picture>

    constructor(attrs: ILogoSlot) {
      super(attrs)
    }
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenAssets.INTERFACES & {
    'content-block': IContentBlock
    'slotted-content': ISlottedContent
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenAssets.ENTITIES,
  'internal-content-page': InternalContentPage,
  'external-content-page': ExternalContentPage,
  'content-stack': ContentStack,
  'html-block': HtmlBlock,
  'external-url-cta-block': ExternalUrlCtaBlock,
  'carousel-block': CarouselBlock,
  'vimeo-block': VimeoBlock,
  'logos-block': LogosBlock,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}
