import getEnv from '../getEnv'

export enum IdentifyAnalyticsMeta {
  'VERSION' = 'Event Sync Console Version',
  'ENVIRONMENT' = 'Event Sync Console Environment',
  'CONSOLE_URL' = 'Event Sync Console URL',
  'CUSTOMER' = 'Event Sync Customer',
  'CUSTOMER_LICENSE' = 'Event Sync Customer License',
  'USER_ID' = 'Event Sync User',
  'LANGUAGE' = 'Event Sync Console Language',
  'EVENT_NAME' = 'Event Sync Event Name',
}

const { VITE_CLIENT } = getEnv()

export const analyticsEnvironment = {
  [IdentifyAnalyticsMeta.CUSTOMER]: VITE_CLIENT || 'Eventsync Console Client',
}

export enum AnalyticsTypenames {
  USER = 'User',
}
